import { CTA_LINK_1 } from "components/data/landing-page-data";
import SurveillancePageV4 from "components/landing-pages/surveillance/SurveillancePageV4";
import GlobalLayout from "components/page/GlobalLayout";
import { SURVEILLANCE_LANDING_PAGE_LINKS_2 } from "components/page/newHeader/lib/menu-data";
import React from "react";
import { Helmet } from "react-helmet";

export default function SurveillanceD() {
  return (
    <GlobalLayout
      color="var(--lp-light-bg)"
      landingPage
      customLinks={SURVEILLANCE_LANDING_PAGE_LINKS_2}
      ctaLink={CTA_LINK_1}
      includeButton
      buttonType="nav-outlined"
      useFullWidthLogo
    >
      <Helmet>
        <title>Security + Surveillance Unified | Rhombus™</title>
        <meta
          name="description"
          content="Stay in the know from anywhere at anytime. Automated alerts and surveillance monitoring ensures your spaces, assets, and people are protected at all times."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SurveillancePageV4 />
    </GlobalLayout>
  );
}
